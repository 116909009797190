import React from 'react';
import { Nav } from '@/components/nav';
import four0FourImg from '@/assets/404.webp';

interface Redirections {
  [key: string]: string;
}

export default function Page404() {
  React.useEffect(() => {
    const pathname = window.location.pathname;

    const urlArray = [
      `/blogs/you-can-not-hate-anyone-if-you-do-this`,
      `/cookie-policy`,
      `/privacy-policyWhether`,
      `/-and-conditions`,
      `/biggest-turn-off-mid-run-side-stitch/`,
      `/-and-conditionsPol`,
      `/_documents`,
      `https://level.game/blogs/digestive-system-using-clay`,
    ];
    const redirections: Redirections = {
      // New///
      '/share/gm1EUbtB7kLvkJGt9': `/blogs/`,
      '/blogs/level.game/blogs/the-monk-who-studied-at-ucl-a-journey-of-transformation': `/blogs/the-monk-who-studied-at-ucl-a-journey-of-transformation`,
      // Old

      '/cancellation-and-refund-policy.tsx': `/cancellation-and-refund-policy`,
      '/PratikSonawane10': `/#download`,
      '/blogs/your-actual-evil-eye-in-real-life-': `/blogs/om-mani-padme-hum-meaning-benefits-and-pronounciation`,
      '/blogs/your-actual-evil-eye-in-real-life': `/blogs/maa-tara-mantra-meaning-benefits-and-best-time`,
      '/blogs/the-secret': `/blogs/jeff-weiner-former-linkedin-ceo-who-meditates-everyday`,
      '/blogs/5-imp': `/blogs/5-simple-steps-on-what-you-should-after-meditation`,
      '/blogs/sustained-weight-loss-got-easier': `/blogs/intuitive-eating-losing-weight-without-dieting-by-listening-to-your-body`,
      '/blogs/om-chanting-benefits-methods-pronounciation': `/blogs/om-chanting-benefits-methods-pronounciation-additional-facts`,
      "/blogs/how-to-do-meditation-for-overthinkers'": `/blogs/how-to-do-meditation-for-overthinkers`,
      "/blogs/maa-tara-mantra-meaning-benefits-and-best-time'": `/blogs/maa-tara-mantra-meaning-benefits-and-best-time`,
      // '/blogs/you-can-not-hate-anyone-if-you-do-this': `/blogs/`,
      // '/cookie-policy': `/blogs/`,
      // '/privacy-policyWhether': `/blogs/`,
      // '/-and-conditions': `/blogs/`,
      // '/biggest-turn-off-mid-run-side-stitch/': `/blogs/`,
      // '/-and-conditionsPol': `/blogs/`,
      // '/_documents': `/blogs/`,
      // 'https://level.game/blogs/digestive-system-using-clay': `/blogs/`,

      '/blogs/Motivational': `/blogs/motivational-quotes-for-you-to-get-up-and-ditch-netflix-and-hit-the-books-in-no-time`,
      'https://level.game/blogs/what-is-core-sleep': `/blogs/what-is-core-sleep`,
    };

    urlArray.forEach((url) => {
      redirections[url] = `/blogs/`;
    });

    if (window.location.pathname === `/api/level.php`) {
      const url = window.location.search.split(`https://`);

      const decodedUrl = decodeURIComponent(`https://${url[1]}`);

      if (url[1]) {
        window.location.href = decodedUrl;
      } else {
        window.location.href = `https://level.game/blogs`;
      }
    }

    if (redirections.hasOwnProperty(pathname)) {
      window.location.href = redirections[pathname];
    }
  }, []);
  return (
    <>
      <Nav />

      <div className="md:mt-40 flex items-center justify-center flex-col text-center p-2 text-gray-200">
        <h1
          className="font-thin text-4xl"
          // style={{ color: `#141414` }}
        >
          Oopsy, Page Not Found!
        </h1>

        <h3
          className="text-6xl font-black mt-4"
          //  style={{ color: `#141414` }}
        >
          404 Error
        </h3>

        {/* eslint-disable-next-line */}
        <img
          src={four0FourImg.src}
          className="max-w-5xl m-auto block mt-20"
          style={{ width: `100%` }}
          alt="404 image"
        />

        {/* eslint-disable-next-line */}
        <a
          href="/"
          className="py-4 px-12 font-bold text-white border-4 text-xl mb-10"
          style={{
            background: `#f9ce47`,
            boxShadow: `inset 0px 1px 13px #00000052, 0px 3px 6px #00000029`,
            borderRadius: `1rem 0.2rem 1rem 0.2rem`,
          }}
        >
          BACK TO HOME
        </a>
      </div>
    </>
  );
}
